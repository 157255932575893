import axios from "axios";
import apiConfig from "@/config/api.config";
import { requestInterceptor, responseInterceptor } from "./interceptors";
import { identityRequestInterceptor } from "../identity/identity.interceptors";

/**
 * Creates an instance of the axios which will be used to send api calls
 * throughout the application
 */
export const $http = axios.create({
  baseURL: apiConfig.API_BASE_URL,
});

/**
 * Intercepts all the app api requests
 */
$http.interceptors.request.use(requestInterceptor);

/**
 * Intercepts api response
 */
$http.interceptors.response.use(undefined, responseInterceptor);

/**
 * Create public client
 * @public
 */
export const $public = axios.create({
  baseURL: apiConfig.PUBLIC_API_BASE_URL,
});

export const $accounts = axios.create({
  baseURL: apiConfig.ACCOUNTS_API_URL,
});

$accounts.interceptors.request.use(identityRequestInterceptor);

$accounts.interceptors.response.use(undefined, responseInterceptor);
