<template>
  <div id="app">
    <div class="layout-wrapper">
      <component :is="layout" />
    </div>
  </div>
</template>

<script>
import AppLayout from "@/components/layouts/AppLayout";
import AuthLayout from "@/components/layouts/AuthLayout";

/**
 * Main vue root file of the application
 */
export default {
  /**
  |--------------------------------------------------
  | Components
  |--------------------------------------------------
  */
  components: {
    AppLayout,
    AuthLayout,
  },
  /**
  |--------------------------------------------------
  | Computed properties
  |--------------------------------------------------
  */
  computed: {
    /**
     * Fetches current route's layout
     */
    layout() {
      const { meta } = this.$route;
      if (!meta?.layout) return "";

      const { auth, app } = this.$appConfig.layouts;
      return `${this.isLoggedIn() ? meta.layout ?? app : auth}`;
    },
  },
  /**
  |--------------------------------------------------
  | Methods
  |--------------------------------------------------
  */
  methods: {
    /**
     * Checks whether user is logged in or not from the localstorage persisted data
     * @returns {Boolean} Is logged in or not
     */
    isLoggedIn() {
      return (
        JSON.parse(localStorage.getItem("maropost-identity"))?.auth
          ?.isLoggedIn ?? false
      );
    },
  },
};
</script>
