import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";

import "@/styles/global.scss";
import { injectInjectables, registerDirectives } from "@/utils";

import "@/plugins/dayjs";

Vue.config.productionTip = false;

injectInjectables();

/**
 * Registers directives globally in vue
 */
registerDirectives();

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
