import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

/**
 * Vuetify config settings
 */
export default new Vuetify({
  theme: {
    light: true,
    themes: {
      light: {
        white: "#ffffff",
        black: "#4c5463",
        "dark-black": "#1E2533",
        primary: "#03B6FC",
        loginBtn: "#1E2533",
        error: "#b00020",
        header: "#ffffff",
        "dark-grey": "#616161",
        "light-blue": "#006BAF",
      },
    },
  },
});
