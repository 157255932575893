import authRoutes from "./auth.routes";
import appRoutes from "./app.routes";
import publicRoutes from "./public.routes";

/**
 * Application routes
 */
const routes = Object.freeze([...authRoutes, ...appRoutes, ...publicRoutes]);

export default routes;
