/**
 * Gainsight track global directive, add this attribute to dom element you wanna track
 * @description Binds a custom [data-gs] attribute with its custom value with the Dom element
 * when it binds in the DOM.
 * @example v-track="track-me"
 */
const track = {
  bind: function (el, binding) {
    // Adds attributes to dom element
    el.setAttribute("data-gs", binding.value);
  },
};

/**
 * focus
 * Focuses input field
 */
const focus = {
  inserted: function (el) {
    // Recursion based function for finding an input
    // nested within other elements.
    let findInput = (el, max_depth = 5) => {
      // We found the input, so we return it, which causes
      // the entire function stack to pop
      if (el.nodeName === "INPUT") {
        return el;
      }

      // Prevent infinite recursion by providing a maximum
      // depth, and returning when we've reached that depth
      if (max_depth === 0) {
        return null;
      }

      // Our current element is not an input, so we need to loop
      // over its children and call findInput recursively
      for (let child of el.children) {
        let input = findInput(child, max_depth - 1);

        // We've found our input, return it to unwind the stack
        // otherwise, continue through the loop
        if (input) {
          return input;
        }
      }

      // Fallback in case for when el has no children, or we reached the end of the loop with no input
      return null;
    };

    // Start searching for the input.  We can optionally
    // pass a higher max_depth.  Use with caution.
    let input = findInput(el, 20);

    if (input) input.focus();
  },
};

/**
 * Global directives object
 */
const directives = {
  track,
  focus,
};

export default directives;
