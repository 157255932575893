/**
 * Api's configuration
 */
const config = {
  /**
   * Api base url
   */
  API_BASE_URL: process.env.VUE_APP_API_BASE_URL,
  PUBLIC_API_BASE_URL: process.env.VUE_APP_PUBLIC_API_BASE_URL,
  ACCOUNTS_API_URL: process.env.VUE_APP_ACCOUNTS_API_URL,
  CONNECTOR_APP_BASE_URL: process.env.VUE_APP_CONNECTOR_APP_BASE_URL,
};

export default Object.freeze(config);
