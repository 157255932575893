import VuexPersistence from "vuex-persist";

/**
 * Persist config
 */
const persistConfig = {
  /**
   * Locastorage key which contains store data s its value
   */
  key: "maropost-identity",
  /**
   * Name of store modules to be persisted
   */
  modules: ["ui", "auth", "unified"],
  /**
   * Browser storage to save the store
   */
  storage: window.localStorage,
};

/**
 * Vuex local persist
 */
const vuexPersist = new VuexPersistence(persistConfig);

export default vuexPersist;
