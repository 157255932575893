import store from "@/store";
import { determineTokenValidity } from "@/utils";

/**
 * Request interceptor to intercept all request sent to identity server
 * @returns {Request}
 */
export const identityRequestInterceptor = async (config) => {
  try {
    let token = store.getters["auth/cookieToken"]?.token;

    if (token) {
      const isTokenExpired = await determineTokenValidity(token);

      if (isTokenExpired) {
        await store.dispatch("auth/updateUserSession");
        token = store.getters["auth/cookieToken"]?.token ?? "";
      }

      config.headers["Authorization"] = token;
    }
    return config;
  } catch (error) {
    console.warn(error);
    return config;
  }
};
