/**
 * Env config
 */
const ENV_CONFIG = process.env;

/**
 * Firebase configrations
 */
const firebaseConfig = {
  apiKey: ENV_CONFIG.VUE_APP_FIREBASE_API_KEY,
  authDomain: ENV_CONFIG.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: ENV_CONFIG.VUE_APP_FIREBASE_PROJECT_ID,
};

export default Object.freeze(firebaseConfig);
