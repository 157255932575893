import { isType } from "@/utils";

/**
 * Gainsight tracking mixin
 * @description Contains the gaoinsight tracking functions and logic
 * @author {Jatin Kamboj}
 */
export default {
  name: "GainsightMixin",
  /**
  |--------------------------------------------------
  | Methods
  |--------------------------------------------------
  */
  methods: {
    /**
     * Initialises a new user to gainsight
     */
    async initialiseUser(user = null) {
      if (!user) return;

      //passing user and account objects:
      const aptrinsic = window.aptrinsic;
      if (isType(aptrinsic, "function")) {
        aptrinsic("identify", {
          //User Fields
          id: user?.uid,
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          signUpDate: new Date().getTime(), //unix time in ms
        });
      }
    },
    triggerEvent(type, event, config) {
      if (isType(window.aptrinsic, "function")) {
        window.aptrinsic(type, event, config);
      }
    },
    /**
     * Reset gainsight seesion of a user
     * @description resets the tracked user session
     */
    resetSession() {
      //passing user and account objects:
      const aptrinsic = window.aptrinsic;
      if (isType(aptrinsic, "function")) aptrinsic("reset");
    },
  },
};
