var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{attrs:{"id":"maropost-app","app":"","clipped-right":"","clipped-left":"","color":"header","tag":"header","light":""}},[_c('v-app-bar-nav-icon',{on:{"click":_vm.toggleAppMenu}}),_c('v-toolbar-items',{staticClass:"w-100 pl-2"},[_c('v-menu',{attrs:{"offset-y":"","value":_vm.isProfileMenuOpen},on:{"input":function($event){_vm.isProfileMenuOpen = !_vm.isProfileMenuOpen}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"track",rawName:"v-track",value:('maropost-profile-button'),expression:"'maropost-profile-button'"}],staticClass:" pr-3",class:_vm.headerClass,attrs:{"text":"","id":"top-left__dropdown"}},on),[_c('v-icon',{staticClass:"pa-1 mr-2 white elevation-1 rounded-circle d-inline-block",attrs:{"medium":"","color":"#03B6FC"},domProps:{"textContent":_vm._s('mdi-account-circle-outline')}}),_c('span',{staticClass:"\n              text--primary\n              d-none d-sm-flex\n              subtitle-2\n              secondary--font\n              font-weight-large\n              text-capitalize\n            "},[_vm._v(" Maropost Profile ")]),_c('v-icon',{attrs:{"color":"dark-grey"}},[_vm._v(" "+_vm._s(_vm.profileMenuIcon)+" ")])],1)]}}])},[_c('v-list',[_c('v-list-item',{directives:[{name:"track",rawName:"v-track",value:('marketing-cloud-btn'),expression:"'marketing-cloud-btn'"}],staticClass:"px-5 text-black--80 cluster--link",attrs:{"link":"","dense":""},on:{"click":function($event){$event.preventDefault();return _vm.openMarketing.apply(null, arguments)}}},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('img',{attrs:{"height":"25","src":_vm.$images.marketingCloudIcon}})]),_c('v-list-item-content',{staticClass:"pl-3 text-capitalize secondary--font caption"},[_vm._v(" Maropost Cloud ")])],1),_vm._l((_vm.clusters),function(ref){
var link = ref.link;
var element_id = ref.element_id;
return [_c('v-list-item',{directives:[{name:"track",rawName:"v-track",value:(element_id),expression:"element_id"}],key:link,staticClass:"px-5 text-black--80 cluster--link",attrs:{"link":"","dense":"","href":link},on:{"click":function($event){$event.preventDefault();return _vm.openCluster(link)}}},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-img',{attrs:{"width":"17px","alt":"Maropost Icon","src":_vm.$images.maropostIcon}})],1),_c('v-list-item-content',{staticClass:"pl-3 secondary--font caption"},[_vm._v(" "+_vm._s(_vm.getDomainName(link))+" ")])],1)]})],2)],1),_c('v-spacer'),_c('v-menu',{attrs:{"offset-y":"","value":_vm.isMenuOpen},on:{"input":function($event){_vm.isMenuOpen = !_vm.isMenuOpen}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"pl-4 pr-3",class:_vm.headerClass,attrs:{"text":"","id":"top-right__dropdown"}},on),[_c('v-icon',{staticClass:"pr-1",attrs:{"medium":"","color":"dark-grey"},domProps:{"textContent":_vm._s('mdi-account-circle')}}),_c('span',{staticClass:"\n              text--primary\n              d-none d-sm-flex\n              subtitle-2\n              secondary--font\n              font-weight-large\n              text-capitalize\n            "},[_vm._v(" "+_vm._s(_vm.username)+" ")]),_c('v-icon',{attrs:{"color":"dark-grey"}},[_vm._v(" "+_vm._s(_vm.menuIcon)+" ")])],1)]}}])},[_c('v-list',{staticClass:"w-100 py-1",attrs:{"dense":""}},[_c('v-list-item-group',{attrs:{"color":""}},[_c('v-list-item',{directives:[{name:"track",rawName:"v-track",value:('logout-user-btn'),expression:"'logout-user-btn'"}],staticClass:"px-5 text-black--80",staticStyle:{"min-height":"36px !important"},attrs:{"link":"","dense":""},on:{"click":_vm.logoutUser}},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',{domProps:{"textContent":_vm._s(_vm.logoutAction.icon)}})],1),_c('v-list-item-content',{staticClass:"pl-3 text-capitalize secondary--font caption"},[_vm._v(" "+_vm._s(_vm.logoutAction.name)+" ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }