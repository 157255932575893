import store from "@/store";
import { capitalize, isEmpty, isType } from "@/utils";
import { RESPONSE_CODES } from "@/constants/app";

import appConfig from "@/config/app.config";

const { snackbar, commonErrorMessage } = appConfig;

/**
 * Intercepts all api request which are sent using the axios instance globally
 * @param {Object} config request
 * @returns Api request
 */
export const requestInterceptor = (config) => {
  config.withCredentials = true;
  return config;
};

/**
 * Iterates over error object and concats error messages into a single string
 * @param {Object} errors Object containing api error messages
 * @param {Object} snackbarError Object refference to be used to show error message in the snackbar
 */
const showErrorMessage = (errors, snackbarError) => {
  let message = commonErrorMessage;
  const showSnackbar = !store.getters["ui/hideSnackbar"];

  if (errors && errors?.errors && isType(errors?.errors, "string")) {
    message = capitalize(errors.errors);
  } else if (!isEmpty(errors)) {
    message = Object.keys(errors).reduce((acc, prop) => {
      return acc.concat(`${capitalize(prop)} ${errors[prop][0] ?? ""}`, " ");
    }, "");
  }
  // Fallback common error message to be shown to the user
  snackbarError.message = message;
  if (showSnackbar) store.dispatch("ui/setSnackbar", snackbarError);
};

/**
 * Intercepts all the api response error returned by the api's
 */
export const responseInterceptor = async (error) => {
  let isUnauthorised = false;
  let snackbarError = {
    value: true,
    message: "",
    type: snackbar.snackbarTypes.error,
  };

  if (error.response) {
    const statusCode = error.response?.status;
    const { data } = error.response;
    const isSessionVerifying = store.getters["auth/isSessionVerifying"];

    /*
     * The request was made and the server responded with a
     * status code that falls out of the range of 2xx
     */
    switch (statusCode) {
      /**
       * Dispatches an action in the store to logout the user if (401) status code
       */
      case RESPONSE_CODES.unAuthorised:
        if (!isSessionVerifying) await store.dispatch("auth/logoutUser");

        break;

      /**
       * Handles errors of (404) status code
       */
      case RESPONSE_CODES.notFound:
        showErrorMessage(data, snackbarError);
        break;

      /**
       * Handles (400) error response case
       */
      case RESPONSE_CODES.badRequest:
      case RESPONSE_CODES.forbidden:
      case RESPONSE_CODES.internalServerError:
        showErrorMessage(data, snackbarError);
        break;
    }
  } else {
    /**
     * Handles error case for network error
     * @description dispatches an action in the store to mutate the state of snackbar in (ui) module
     */
    snackbarError.message = error.message;
    store.dispatch("ui/setSnackbar", snackbarError);
  }
  return !isUnauthorised && Promise.reject(error);
};
