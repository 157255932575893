/**
 * Application views text value to be shown to the user
 */
const app = {
  // Forgot pasword view
  forgotPassword: {
    form: {
      title: "Forgot Your Password?",
      subtitle: "No worries, just enter your registered email address",
      invalidUserMsg: "Sorry, there is no account with that email address.",
    },
  },
  // Reset password view and form
  resetPassword: {
    form: {
      title: "Reset Your Password",
    },
    errorMessages: {
      passwordInvalid: "New password is not valid.",
      passwordMisMatch: "New password and Confirm password must match.",
      weakPassword: "Password is weak.",
      linkTokenExpired: "Link is expired.",
      accountDisabled: "Account is disabled.",
    },
    successMessages: {
      passwordChanged: "Password changed successfully",
    },
  },
  // Forgot password confirmation view
  forgotPasswordConfirmation: {
    title: "Password Reset Email Sent",
    text: `We’re checking our records to find the InboxAware account for`,
    subtext: `If we find a match, you’ll get an email with further instructions. 
      If you don't receive anything, 
      please check that you’ve entered the correct email address and check your spam folder.`,
  },
};

export default Object.freeze(app);
