
import "firebase/firestore";
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, query } from "firebase/firestore";
import firebaseConfig from "@/config/firebase.config.js";

// Firebase app initialisaton
const firebaseApp = initializeApp(firebaseConfig);

// Firestore database
export const db = getFirestore(firebaseApp);

// Users collection in firestore
export const userCollection = query(collection(db,"users"));

export default firebaseApp;
