import router from "@/router";
import { toMarketingCluster, isEmpty } from "@/utils";

/**
 * manageUserRedirectionAfterLogin
 * Manages user redirection to apps after successfull login attempt
 */
export const manageUserRedirectionAfterLogin = (config = {}) => {
  const { isUserAlreadyLoggedIn, getters, rootGetters } = config;

  // Handles connector app redirection and configuration
  if (!isEmpty(rootGetters["ui/connectorAppParams"])) {
    router.push({
      name: "account-selector",
      query: rootGetters["ui/connectorAppParams"],
    });
    return;
  }

  // Handles marketing app
  if (
    (!isUserAlreadyLoggedIn && getters?.isLoggedIn) ||
    getters?.isReffererMaropostProduct
  ) {
    toMarketingCluster(getters?.cookieToken);
  } else router.push({ name: "dashboard" });
};
