<template>
  <div id="salesforce-saml-assertion__wrapper">
    <form
      ref="form"
      method="post"
      class="d-none"
      :action="action"
      id="salesforce-saml-assertion__form"
    >
      <input
        type="hidden"
        id="saml-response"
        name="SAMLResponse"
        :value="samlResponse"
      />
      <input
        type="hidden"
        id="relay-state"
        name="RelayState"
        :value="relayState"
      />
      <input type="submit" value="Submit" v-text="'Submit Salesforce'" />
    </form>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
/**
 * Sales force Saml assertion for login via Maropost Idp
 * @author {Jatin Kamboj}
 */
export default {
  name: "SalesforceSamlAssertionForm",
  /**
|--------------------------------------------------
| Custom evemts
|--------------------------------------------------
*/
  emits: ["show-loader"],
  /**
  |--------------------------------------------------
  | Props
  |--------------------------------------------------
  */
  props: {
    action: { type: String, required: true, default: "" },
    samlResponse: { type: String, required: true, default: "" },
    relayState: { type: String, required: true, default: "" },
  },
  /**
  |--------------------------------------------------
  | Methods
  |--------------------------------------------------
  */
  methods: {
    ...mapMutations({
      setSamlFormSubmitted: "auth/SET_SUBMIT_GALAXY_SAML_FORM",
    }),
    /**
     * @emits show-loader event to show loader to the user
     */
    showLoader(val) {
      this.$emit("show-loader", val);
    },
    /**
     * Submits galaxy saml form
     */
    async submitForm() {
      this.$router.replace({ query: {} });
      this.showLoader(true);

      this.$refs.form.submit();
      this.setSamlFormSubmitted(false);
    },
  },
  /**
  |--------------------------------------------------
  | Mounted lifecycle hook
  |--------------------------------------------------
  */
  mounted() {
    this.submitForm();
  },
};
</script>
