<template>
  <v-navigation-drawer
    v-model="toggleDrawer"
    :mini-variant="toggleMini"
    :permanent="$vuetify.breakpoint.mdAndUp"
    :absolute="$vuetify.breakpoint.smAndDown"
    :temporary="$vuetify.breakpoint.smAndDown"
    :mini-variant-width="60"
    dark
    color="#1D2533"
    id="app-navbar"
    class="right-stretch"
  >
    <v-divider></v-divider>

    <v-list dense class="pa-0">
      <v-list-item
        v-for="item in $navconfig.navList"
        :key="item.title"
        link
        :to="item.path"
      >
        <v-list-item-icon class="mr-0 mt-4">
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content class="text-uppercase pl-3">
          <v-list-item-title class="font-weight-bold body-2">
            <span class="secondary--font"
              >{{ item.title }}
            </span></v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
export default {
  name: "AppNavigation",
  computed: {
    toggleMini() {
      return this.$vuetify.breakpoint.smAndDown
        ? false
        : this.$store.getters["ui/appMenu"];
    },
    
    /* get set drawer value */

    toggleDrawer: {
      get() {
        return this.$store.getters["ui/appMenu"];
      },
      set(value) {
        if (value == false) {
          this.$store.dispatch("ui/appMenu",value);
        }
      },
    },
  },

};
</script>

<style lang="scss" scoped>
#app-navbar {
  .v-icon,
  .v-list-item {
    color: #aaacb1 !important;
  }
  .v-list-item {
    min-height: 59px !important;
  }
  .v-list-item--active {
    -webkit-box-shadow: 5px 0 0 #03b6fc inset;
    box-shadow: inset 5px 0 0 #03b6fc;
    color: #fff !important;
    .v-icon,
    .v-list-item__title {
      color: #fff !important;
    }
  }
}
</style>
