import Vue from "vue";
import routes from "./routes";
import VueRouter from "vue-router";

import store from "@/store";
import { auth } from "./middlewares";
import { isEnvTest } from "@/utils";

/**
 * Don't install router in test mode
 */
if (!isEnvTest()) {
  Vue.use(VueRouter);
}

/**
 * Vue router configuration
 */
const router = new VueRouter({
  routes,
  scrollBehavior: (to, from) => {
    const isUserLoggedIn = store.getters["auth/isLoggedIn"];
    isUserLoggedIn && store.commit("ui/SET_ROUTER_HISTORY", from);
  },
});

/**
 * Router middlware
 * @description Gets executed before an route in the application is resolved
 */
router.beforeEach(auth);

export default router;
