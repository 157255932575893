import {  
  getAuth,
  signInWithEmailAndPassword,
  fetchSignInMethodsForEmail as fetchMethodsForEmail,
  GoogleAuthProvider,
  useDeviceLanguage,
  OAuthProvider,
  signInWithPopup,
  EmailAuthProvider,
  verifyPasswordResetCode, 
  confirmPasswordReset,
  checkActionCode as checkAction,
  applyActionCode as applyAction,
  sendEmailVerification,
  reauthenticateWithCredential,
  multiFactor,
  updatePassword,
  signOut 
  } from 'firebase/auth';
import { $http, $public } from "./apis/http/http";
import "./firebase";

const { VUE_APP_SALESFORCE_SAML_URL } = process.env;

/**
 * Firebase auth service
 */
export const auth = getAuth();

/**
 * signInWithEmailAndPassword
 * @param {Object} credentials Credentials of the user who is trying to login
 * @returns {Promise}
 */
export const signInUser = ({ email, password }) => {
  return signInWithEmailAndPassword(auth, email, password);
};

/**
 * Fetches the Jwt token of the current login user
 * @returns {String} Jwt token of the current user
 */
export const getCurrentUserIdToken = (forceRefresh = false) => {
  return auth?.currentUser?.getIdToken(forceRefresh);
};

/**
 * Fetches the logged in user details
 * @returns {Object} Logged in user details
 */
export const getCurrentUser = () => auth?.currentUser ?? {};

/**
 * signOutUser
 * @description Log outs the logged in user
 */
export const signOutUser = () => signOut(auth);

/**
 * Fetches the list of sign in methods of a created user in the gip
 * @param {String} email Email of the user whom sign in methods are to be fetched
 * @returns {Array} Array of string which contains signin methods of the user
 */
export const fetchSignInMethodsForEmail = (email) => {
  return fetchMethodsForEmail(auth,email);
};

/**
 * Sends reset email to the user whose password is to reset
 * @returns {Promise}
 */
export const resetPasswordEmail = (email) => {
  return $public.post("reset-password", { email });
};

/**
 * Sends post request to Maropost identity server with JWT token
 * and sets the cookie in the browser
 */
export const setCookie = (payload) => {
  return $http.post("session/create", payload);
};

/**
 * Sends get request to Maropost identity server to remove and expire the cookie
 */
export const removeCookie = () => {
  return $http.get("session/destroy");
};

/**
 * Asserts salesforce saml authentication
 * @param {Object} payload Salesforce auth payload
 * @returns {Promise}
 */
export const assertSalesforceAuth = (payload) => {
  return $http.post(`${VUE_APP_SALESFORCE_SAML_URL}/saml/auth`, payload);
};

/**
 * Fetch logged in user mfa details
 * @returns {Array} Current user mfa details
 */
export const getCurrentUserMfaDetails = () => {
  return multiFactor(auth?.currentUser).enrolledFactors;
};

/**
 * Disbale current logged in user primary multi factor
 * @returns {Promise}
 */
export const disablePrimaryMfa = (multifactor) => {
  return multiFactor(auth?.currentUser).unenroll(multifactor);
};

/**
 * Verifies the oobCode or token sent to the user email id to reset the password
 * @param {String} oobCode Code or token to be verified before updating the user password
 * @returns {Promise} with email address of the user where the reset password code or token was sent
 * @author {Jatin Kamboj}
 */
export const verifyPasswordResetTokenOrOobCode = (oobCode) => {
  return verifyPasswordResetCode(auth,oobCode);
};

/**
 * Verifies the OobCode and changes the user password on the basis of the OobCode sent to the user email address
 * @param {String} oobCode OobCode or token sent to the user email address
 * @param {String} newPassword New password which is to updated for the user account
 * @returns {Promise} Password changed successfully or an error code with error message
 * @author {Jatin Kamboj}
 */
export const confirmOobCodeAndResetPassword = (oobCode, newPassword) => {
  return confirmPasswordReset(auth, oobCode, newPassword);
};

/*
 * Reauthenticates the current user with fresh credentials
 * @param {String} param0
 * @returns Promise
 */
export const reauthenticateUserWithCredential = ({ email, password }) => {
  // Creates the credential of the user
  const credential = EmailAuthProvider.credential(
    email,
    password
  );
  // Now using the credentials for reauthentication
  return reauthenticateWithCredential(auth?.currentUser , credential);
};

/**
 * Resets the user password
 * @param {String} password New password of the user
 * @returns {Promise}
 */
export const resetPassword = (password) => {
  return updatePassword(getCurrentUser() , password);
};

/**
 * Get Saml Token
 * @param {Object} payload saml auth payload
 * @returns {Promise}
 */
export const getSAMLToken = (payload) => {
  return $http.get(`${VUE_APP_SALESFORCE_SAML_URL}/saml/saml_data`, payload);
};

/**
 * Updates the username of the current user in the cookie _mp_user_profile
 * @param {Object} params request query params
 * @param {String} token user token
 * @returns {Promise}
 */
export const updateUserNameCookie = (params, token) => {
  const headers = { Authorization: token };
  return $http.get("/session/profile", { params, headers });
};

/*
 * Sends email verification email to the current logged in user
 * whose email is not verified
 * @returns {promise}
 */
export const sendVerificationEmail = (
  actions = { url: window.location.href }
) => {
  return sendEmailVerification(auth.currentUser,actions);
};

/**
 * Verify the user marketing session (Cookie)
 * @returns {Promise}
 */
export const verifyMarketingSession = () => {
  return $http.get("/session/verify");
};

/**
 * Fetches the baracode image in base64 format which is to be used to setup
 * Authenticator app (MFA) for logged in user
 * @param {String} idToken Logged in user Jwt token
 */
export const getAuthenticatorAppBarcode = (idToken) => {
  return $http.post("session/totp_image", { idToken });
};

/**
 * Verifies and enrolls Authenticator app mfa for logged in user
 * @param {String} idToken Logged in user Jwt token
 * @param {String} code TOTP code entered by the user from Authenticator App
 */
export const enrollAuthenticatorAppMfa = ({ idToken, code }) => {
  return $http.post("session/totp/create", { idToken, code });
};

/**
 * Disables TOTP autheticator app Mfa of an user
 * @param {String} idToken Logged in user Jwt token
 */
export const disableAuthenticatorAppMfa = ({ idToken }) => {
  return $http.post("/session/disable_totp", { idToken });
};

/**
 * googleAuthProvider
 * Creates a new google auth provider object
 * @returns GoogleAuthProvider
 */
export const googleAuthProvider = () => {
  return new GoogleAuthProvider();
};

/**
 * oauthProvider
 * Creates a new Oauth provider
 * @returns {OAuthProvider}
 */
export const oauthProvider = (provider = "microsoft.com") => {
  return new OAuthProvider(provider);
};

/**
 * singInWithPopupProvider
 * @returns signInWithPopup
 */
export const singInWithPopupProvider = (provider) => {
  return signInWithPopup(auth,provider);
};

/**
 * useDeviceSpecificLanguage
 * @description To apply the default browser language preference instead of explicitly setting it.
 */
export const useDeviceSpecificLanguage = () => {
  return useDeviceLanguage(auth);
};

/**
 * Checks a verification code sent to the user by email or other out-of-band mechanism.
 * @param code — A verification code sent to the user.
 * @return metadata about the code.
 */
export const checkActionCode = (oobCode) => {
  return checkAction(auth, oobCode);
};

/**
 * Applies a verification code sent to the user by email or other out-of-band mechanism.
 * @param code — A verification code sent to the user.
 * @return {Promise}
 */
export const applyActionCode = (oobCode) => {
  return applyAction(auth, oobCode);
};
