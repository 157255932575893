import { userAccounts } from "@/services/users";

/**
|--------------------------------------------------
| Module state
|--------------------------------------------------
*/
const state = {
  accounts: null,
  cachedAccounts: null,
};

/**
 * Mutations constants
 */
const SET_ACCOUNTS = "SET_ACCOUNTS";
const SET_CACHED_ACCOUNTS = "SET_CACHED_ACCOUNTS";

/**
|--------------------------------------------------
| Mutations
|--------------------------------------------------
*/
const mutations = {
  [SET_ACCOUNTS](state, data = []) {
    state.accounts = data;
  },
  [SET_CACHED_ACCOUNTS](state, data = []) {
    state.cachedAccounts = data;
  },
};

/**
|--------------------------------------------------
| Actions (Asynchronous)
|--------------------------------------------------
*/
const actions = {
  async getAccounts({ commit }, payload) {
    try {
      let { data } = await userAccounts(payload);
      const accounts = data?.data ?? [];

      commit(SET_ACCOUNTS, accounts);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  setCachedAccounts({ commit }, data = []) {
    commit(SET_CACHED_ACCOUNTS, data);
  },
};

/**
|--------------------------------------------------
| Getters to access state properties
|--------------------------------------------------
*/
const getters = {
  userAccounts: ({ accounts }) => accounts,
};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
