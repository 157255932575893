import { deepFreeze } from "@/utils";

/**
 * Gip auth error codes
 */
export const AUTH_GIP_ERROR_CODES = {
  userNotFound: "auth/user-not-found",
  wrongPassword: "auth/wrong-password",
  accountNotFound: "auth/account-not-found",
  mfaRequired: "auth/multi-factor-auth-required",
  accountAlreadyExists: "auth/account-exists-with-different-credential",
  providerAlreadyLinked: "auth/provider-already-linked",
  weakPassword: "auth/weak-password",
  accountDisabled: "auth/user-disabled",
  invalidUserActionCode: "auth/invalid-user-action-code",
};
/**
 * Firebase user details property to be fetched from firebase user
 * object
 */
export const FIREBASE_USER_DETAILS = Object.freeze([
  "email",
  "uid",
  "firstName",
  "lastName",
  "companyName",
  "phoneNumber",
  "photoURL",
  "displayName",
  "emailVerified",
]);

/**
 * Error messages
 */
export const ERROR_MESSAGES = Object.freeze({
  enableMfa: "Enable MFA to access Marketing Cloud.",
  mfaError: "User requires 2FA",
  userDoesNotExists: "User doesn’t exist on {{domain_name}}",
  default: "Something went wrong",
  defaultTryLater: "Something went wrong, Please try again later",
});

/**
 * Gip auth error codes and their error messages
 */
export const AUTH_ERROR_CASE_MESSAGES = Object.freeze({
  "auth/requires-recent-login": "Please login again.",
  "auth/too-many-requests":
    "All requests from this device are blocked. Try again later..",
  "auth/invalid-phone-number": "Invalid phone number.",
  "auth/invalid-verification-code":
    "The 6-digit verification code you provided is invalid.",
  "auth/user-not-found": "Invalid Email or password.",
  "auth/second-factor-already-in-use":
    "Phone Number already enrolled as MFA for this account.",
  "auth/invalid-password": "Invalid Email or password.",
  "auth/wrong-password": "Invalid Email or password.",
  "auth/account-not-found": "Invalid Email or password.",
  EMAIL_NOT_FOUND:
    "If you have a valid account, password recovery instructions have been sent to the registered email address.",
  "auth/invalid-totp-code":
    "The 6-digit verification code you provided is invalid.",
  socialUserNotFound: `To activate this connection please first sign in with your Maropost credentials, or another connected third-party.`,
  "auth/invalid-action-code":
    "The link you are trying to access is invalid. Please contact system support.",
  "auth/invalid-user-action-code":
    "The link you are trying to access is invalid. Please contact system support.",
  "auth/expired-action-code":
    "The link you are trying to access has expired. Please contact system support.",
  "auth/user-disabled":
    "Your account is currently inactive. Please contact system support",
  "auth/user-cancelled": "Login failed! Request cancelled by the user.",
  "auth/maropost-user-not-found": "User doesn’t exist on {{domain_name}}",
  "auth/maropost-account-not-found": `User doesn’t have access to any account on {{domain_name}}`,
//below error code added
  "auth/admin-restricted-operation": "This operation is restricted to administrators only.",
  "auth/captcha-check-failed": "The reCAPTCHA response token provided is either invalid, expired, already used or the domain associated with it does not match the list of whitelisted domains.",
  "auth/code-expired": "The SMS code has expired. Please re-send the verification code to try again.",
  "auth/credential-already-in-use": "This credential is already associated with a different user account.",
  "auth/custom-token-mismatch": "The custom token corresponds to a different audience.",
  "auth/dynamic-link-not-activated": "Please activate Dynamic Links in the Firebase Console and agree to the terms and conditions.",
  "auth/email-change-needs-verification": "Multi-factor users must always have a verified email.",
  "auth/email-already-in-use": "The email address is already in use by another account.",
  "auth/cancelled-popup-request": "This operation has been cancelled due to another conflicting popup being opened.",
  "auth/internal-error": "An internal error has occurred.",
  "auth/invalid-app-credential": "The phone verification request contains an invalid application verifier. The reCAPTCHA token response is either invalid or expired.",
  "auth/invalid-app-id": "The mobile app identifier is not registed for the current project.",
  "auth/invalid-user-token": "This user's credential isn't valid for this project. This can happen if the user's token has been tampered with, or if the user isn't for the project associated with this API key.",
  "auth/invalid-auth-event": "An internal error has occurred.",
  "auth/invalid-continue-uri": "The continue URL provided in the request is invalid.",
  "auth/invalid-cordova-configuration": "The following Cordova plugins must be installed to enable OAuth sign-in: cordova-plugin-buildinfo, cordova-universal-links-plugin, cordova-plugin-browsertab, cordova-plugin-inappbrowser and cordova-plugin-customurlscheme.",
  "auth/invalid-custom-token": "The custom token format is incorrect. Please check the documentation.",
  "auth/invalid-dynamic-link-domain": "The provided dynamic link domain is not configured or authorized for the current project.",
  "auth/invalid-email": "The email address is badly formatted.",
  "auth/invalid-api-key": "Your API key is invalid, please check you have copied it correctly.",
  "auth/invalid-cert-hash": "The SHA-1 certificate hash provided is invalid.",
  "auth/invalid-credential": "The supplied auth credential is malformed or has expired.",
  "auth/invalid-message-payload": "The email template corresponding to this action contains invalid characters in its message. Please fix by going to the Auth email templates section in the Firebase Console.",
  "auth/invalid-multi-factor-session": "The request does not contain a valid proof of first factor successful sign-in.",
  "auth/invalid-oauth-provider": "EmailAuthProvider is not supported for this operation. This operation only supports OAuth providers.",
  "auth/invalid-oauth-client-id": "The OAuth client ID provided is either invalid or does not match the specified API key.",
  "auth/unauthorized-domain": "This domain is not authorized for OAuth operations for your Firebase project. Edit the list of authorized domains from the Firebase console.",
  "auth/invalid-persistence-type": "The specified persistence type is invalid. It can only be local, session or none.",
  "auth/invalid-provider-id": "The specified provider ID is invalid.",
  "auth/invalid-recipient-email": "The email corresponding to this action failed to send as the provided recipient email address is invalid.",
  "auth/invalid-sender": "The email template corresponding to this action contains an invalid sender email or name. Please fix by going to the Auth email templates section in the Firebase Console.",
  "auth/invalid-verification-id": "The verification ID used to create the phone auth credential is invalid.",
  "auth/invalid-tenant-id": "The Auth instance's tenant ID is invalid.",
  "auth/multi-factor-info-not-found": "The user does not have a second factor matching the identifier provided.",
  "auth/multi-factor-auth-required": "Proof of ownership of a second factor is required to complete sign-in.",
  "auth/missing-android-pkg-name": "An Android Package Name must be provided if the Android App is required to be installed.",
  "auth/auth-domain-config-required": "Be sure to include authDomain when calling firebase.initializeApp(), by following the instructions in the Firebase console.",
  "auth/missing-app-credential": "The phone verification request is missing an application verifier assertion. A reCAPTCHA response token needs to be provided.",
  "auth/missing-verification-code": "The phone auth credential was created with an empty SMS verification code.",
  "auth/missing-continue-uri": "A continue URL must be provided in the request.",
  "auth/missing-iframe-start": "An internal error has occurred.",
  "auth/missing-ios-bundle-id": "An iOS Bundle ID must be provided if an App Store ID is provided.",
  "auth/missing-multi-factor-info": "No second factor identifier is provided.",
  "auth/missing-multi-factor-session": "The request is missing proof of first factor successful sign-in.",
  "auth/missing-or-invalid-nonce": "The request does not contain a valid nonce. This can occur if the SHA-256 hash of the provided raw nonce does not match the hashed nonce in the ID token payload.",
  "auth/missing-phone-number": "To send verification codes, provide a phone number for the recipient.",
  "auth/missing-verification-id": "The phone auth credential was created with an empty verification ID.",
  "auth/app-deleted": "This instance of FirebaseApp has been deleted.",
  "auth/account-exists-with-different-credential": "An account already exists with the same email address but different sign-in credentials. Sign in using a provider associated with this email address.",
  "auth/network-request-failed": "A network error (such as timeout, interrupted connection or unreachable host) has occurred.",
  "auth/no-auth-event": "An internal error has occurred.",
  "auth/no-such-provider": "User was not linked to an account with the given provider.",
  "auth/null-user": "A null user object was provided as the argument for an operation which requires a non-null user object.",
  "auth/operation-not-allowed": "The given sign-in provider is disabled for this Firebase project. Enable it in the Firebase console, under the sign-in method tab of the Auth section.",
  "auth/operation-not-supported-in-this-environment": 'This operation is not supported in the environment this application is running on. "location.protocol" must be http, https or chrome-extension and web storage must be enabled.',
  "auth/popup-blocked": "Unable to establish a connection with the popup. It may have been blocked by the browser.",
  "auth/popup-closed-by-user": "The popup has been closed by the user before finalizing the operation.",
  "auth/provider-already-linked": "User can only be linked to one identity for the given provider.",
  "auth/quota-exceeded": "The project's quota for this operation has been exceeded.",
  "auth/redirect-cancelled-by-user": "The redirect operation has been cancelled by the user before finalizing.",
  "auth/redirect-operation-pending": "A redirect sign-in operation is already pending.",
  "auth/rejected-credential": "The request contains malformed or mismatching credentials.",
  "auth/maximum-second-factor-count-exceeded": "The maximum allowed number of second factors on a user has been exceeded.",
  "auth/tenant-id-mismatch": "The provided tenant ID does not match the Auth instance's tenant ID",
  "auth/timeout": "The operation has timed out.",
  "auth/user-token-expired": "The user's credential is no longer valid. The user must sign in again.",
  "auth/unauthorized-continue-uri": "The domain of the continue URL is not whitelisted.  Please whitelist the domain in the Firebase console.",
  "auth/unsupported-first-factor": "Enrolling a second factor or signing in with a multi-factor account requires sign-in with a supported first factor.",
  "auth/unsupported-persistence-type": "The current environment does not support the specified persistence type.",
  "auth/unsupported-tenant-operation": "This operation is not supported in a multi-tenant context.",
  "auth/unverified-email": "The operation requires a verified email.",
  "auth/user-mismatch": "The supplied credentials do not correspond to the previously signed in user.",
  "auth/user-signed-out": "",
  "auth/weak-password": "The password must be 6 characters long or more.",
  "auth/web-storage-unsupported": "This browser is not supported or 3rd party cookies and data may be disabled."
});

/**
 * Display breakpoints
 */
export const DISPLAY_BREAKPOINTS = {
  xs: "xs",
  sm: "sm",
  md: "md",
  lg: "lg",
  xl: "xl",
};

/**
 * Common api respose codes
 */
export const RESPONSE_CODES = {
  success: 200,
  badRequest: 400,
  unAuthorised: 401,
  notFound: 404,
  internalServerError: 500,
  created: 201,
  noContent: 204,
  forbidden: 403,
  unProcessed: 422,
};

/**
 * Invalid maropost product domains to be ignored as a refferer
 * @type {Object}
 */
export const INVALID_MAROPOST_REFFERERS = {
  galaxy: "https://galaxy.maropost.com",
  maropost: "https://www.maropost.com",
  identity: "https://id.maropost.com",
  identityStaging: "https://id-staging.maropost.com",
  identityUat: "https://id-uat.maropost.com",
  adminPanel: process.env.VUE_APP_MAROPOST_ADMIN_PANEL_URL,
};

/**
 * Maropost saml id to be used for Galaxy login
 */
export const MAROPOST_SAML_ID = "maropost_saml_id";

/**
 * Marketing clusters list
 * @todo Update the production cluster server url
 */
export const MARKETING_CLUSTERS = Object.freeze([]);

/**
 * Mfa options in Maropost Identity platform
 */
export const MFA_OPTIONS = deepFreeze([
  {
    name: "Text Message",
    value: "textMessage",
    id: "phone",
    identifier: "mfa-text-message",
  },
  {
    id: "totp",
    name: "Security App",
    value: "totp",
    identifier: "mfa-authenticator-app",
  },
]);

/**
 * Salesfire galaxy domain name
 */
export const GALAXY_DOMAIN = "https://galaxy.maropost.com";

/**
 * USER_PROPS
 * User profile detail properties
 */
export const USER_PROPS = [
  "firstName",
  "lastName",
  "companyName",
  "phoneNumber",
  "mfaProvider",
  "totpSecret",
];

export const RESEND_VERIFICATION_CODE_MILLIS = 1500_0;

/**
 * RECAPTCHA_TYPES
 * Types of recaptcha
 */
export const RECAPTCHA_TYPES = deepFreeze({
  normal: "normal",
  invisible: "invisible",
});

/**
 * AUTH_MESSAGES
 * Auth messages to be shown to the user
 */
export const AUTH_MESSAGES = {
  codeReattemptsExhausted:
    "Maximum re-attempts has been reached. Please try again after 5 minutes.",
  codeResentSuccessfully: "Code has been re-sent successfully.",
};

/**
 * MAROPOST_LOCAL_DOMAIN
 * Local dev maropost domain
 */
export const MAROPOST_LOCAL_DOMAIN = "http://app.maropost.local:8080";

/**
 * EMAIL_ACTION_CODES
 * Email action codes
 */
export const EMAIL_ACTION_CODES = {
  verifyEmail: "verifyEmail",
  recoverEmail: "recoverEmail",
  resetPassword: "resetPassword",
  revertSecondFactorAddition: "revertSecondFactorAddition",
};

export const IDENTITY_AUTH_ERROR_CODES = {
  userNotFound: "auth/maropost-user-not-found",
  accountNotFound: "auth/maropost-account-not-found",
  refreshSession: "401",
  loggedOut: "auth/logged-out",
};

/**
 * App enviroments enum (Immutable)
 */
export const ENVS = deepFreeze({
  development: "development",
  test: "test",
  production: "production",
  staging: "staging",
});

/**
 * Global debounce millis for debounced functions
 */
export const DEBOUNCE_MILLIS = 500;

/**
 * Default records number per page
 */
export const DEFAULT_PER_PAGE_RECORDS = 10;

/**
 * Marketing enviroment based clusters link
 */
export const MARKETING_CLUSTERS_LINK = {
  staging: "https://staging.maropost.com",
  uat: "https://uat.maropost.com",
  sandbox: "https://sandbox.maropost.com",
  production: "https://app.maropost.com",
};

export const MAROPOST_ADMIN_PANEL =
  process.env.VUE_APP_MAROPOST_ADMIN_PANEL_URL ?? "http://localhost:8081";

export const MAROPOST_ADMIN_PANEL_LOCAL = [
  "http://localhost:8080",
  process.env.VUE_APP_MAROPOST_ADMIN_PANEL_URL,
  "https://accounts-sandbox.maropost.com",
];
