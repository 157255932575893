import Vue from "vue";
import Vuex from "vuex";
import * as modules from "./modules";
import { isDevEnv } from "@/utils";
import vuexPersist from "@/plugins/vuex.persist.js";

Vue.use(Vuex);

export default new Vuex.Store({
  modules,
  strict: isDevEnv(),
  devtools: isDevEnv(),
  plugins: [vuexPersist.plugin],
});
