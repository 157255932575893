import appConfig from "@/config/app.config";
const { layouts } = appConfig;

/**
 * Routes meta
 */
const meta = {
  layout: layouts.auth,
  requiresAuth: false,
};

/**
 * Auth routes
 */
const authRoutes = [
  {
    path: "/login",
    name: "login",
    meta,
    component: () =>
      import(/* webpackChunkName: "login-page" */ "@/views/Auth/Login.vue"),
  },
  {
    path: "/forgot-password",
    name: "forgot_password",
    meta,
    component: () =>
      import(
        /* webpackChunkName: "forgot-password" */ "@/views/Auth/ForgotPassword.vue"
      ),
  },
  {
    path: "/logged-out",
    name: "logout",
    meta,
    component: () =>
      import(/* webpackChunkName: "loggedOut" */ "@/views/Auth/LogOut.vue"),
  },
  {
    path: "/forgot-password-confirmation",
    name: "forgot_password_confirmation",
    meta,
    component: () =>
      import(
        /* webpackChunkName: "forgot-password-confirmation" */ "@/views/Auth/ForgotPasswordConfirmation.vue"
      ),
  },
  {
    path: "/reset-password",
    name: "reset_password",
    meta,
    component: () =>
      import(
        /* webpackChunkName: "reset-password" */ "@/views/Auth/ResetPassword.vue"
      ),
  },
  {
    path: "/galaxy-login",
    name: "galaxy_login",
    meta,
    component: () =>
      import(
        /* webpackChunkName: "galaxy-login" */ "@/views/Auth/GalaxyLogin.vue"
      ),
  },
  {
    path: "/account-selector",
    name: "account-selector",
    meta: { ...meta, requiresAuth: true },
    component: () =>
      import(
        /* webpackChunkName: "account-selector" */ "@/views/IntegrationsAccountSelector.vue"
      ),
  },
];

export default authRoutes;
