<template>
  <div id="app-layout">
    <div id="app-layout">
    <v-app :style="themeStyle">
      <div id="maropost-header"><app-header /></div>
      <v-main id="main-section">
        <v-row no-gutters>
          <v-col cols="auto">
            <div id="app-navbar">
              <AppNavigation />
            </div>
          </v-col>
          <v-col
            class="right-stretch"
            id="scroll-layout-section"
          >
            <div id="page-section" class="pt-2 width-100">
              <v-container fluid class="px-8">
                <transition name="fade" mode="out-in">
              <!--------------- Renders vue routes  --------------->
                    <router-view />
                </transition>
              </v-container>
            </div>
          </v-col>
        </v-row>
      </v-main>
      <snackbar
          :snackbarValue="snackbar.value"
          :snackBarType="snackbar.type"
          @close="closeSnackbar"
        >
          <span class="text--primary body-2 white-space-preline">
            {{ snackbar.message }}
          </span>
        </snackbar>
        <salesforce-saml-assertion-form
          v-if="submitSamlForm"
          :action="samlResponse.saml_acs_url"
          :relay-state="samlResponse.relay_state"
          :saml-response="samlResponse.saml_response"
        />
    </v-app>
  </div>
  </div>
</template>

<script>
import AppHeader from "./AppHeader.vue";
import { mapActions, mapGetters } from "vuex";
import Snackbar from "@/components/shared/Snackbar";
import AppNavigation from '@/components/shared/AppNavigation';
import { isEmpty } from "@/utils";
import SalesforceSamlAssertionForm from "@/components/forms/SalesforceSamlAssertionForm.vue";

/**
 * App default layout
 */
export default {
  name: "AppLayout",
  /**
  |--------------------------------------------------
  | Components
  |--------------------------------------------------
  */
  components: { AppHeader, Snackbar, SalesforceSamlAssertionForm, AppNavigation },
  /**
  |--------------------------------------------------
  | Computed properties
  |--------------------------------------------------
  */
  computed: {
    ...mapGetters({
      snackbar: "ui/snackbar",
      previousRoute: "ui/previousRoute",
      samlResponse: "auth/samlResponse",
      isSubmitSamlForm: "auth/submitSamlForm",
      isGalaxySamlAssertionValid: "auth/isGalaxySamlAssertionValid",
    }),
    /**
     * Computes app layout backgroud style on the basis of the current theme
     * being used in the application
     */
    themeStyle() {
      return { background: this.$vuetify.theme.themes.light.background };
    },
    /**
     * Is previous route was login or not
     * @type {Boolean}
     */

    isPreviousRouteLogin() {
      return (
        this.previousRoute?.name === "login" &&
        this.previousRoute?.fullPath?.includes("maropost_saml_id")
      );
    },
    /**
     * Determines that saml form needs to be submitted or not
     * @type {Boolean}
     */
    submitSamlForm() {
      return (
        !isEmpty(this.samlResponse) &&
        this.isSubmitSamlForm &&
        this.isGalaxySamlAssertionValid &&
        !this.isPreviousRouteLogin
      );
    },
  },
  /**
  |--------------------------------------------------
  | Actions
  |--------------------------------------------------
  */
  methods: {
    isEmpty,
    ...mapActions({
      setSnackbar: "ui/setSnackbar",
    }),
    /**
     * Closes the snackbar and dispactches the action in the store
     */
    closeSnackbar(value) {
      const data = { value, message: "", previousError: this.snackbar.message };
      this.setSnackbar(data);
    },

    /**
     * Remove Snackbar
     */
    removeSnackbar(){
      this.closeSnackbar(false);
    }
  },
  /**
  |--------------------------------------------------
  | Mouned lifecycle hook
  |--------------------------------------------------
  */
  mounted(){
    window.addEventListener("beforeunload", this.removeSnackbar);
  },
  /**
  |--------------------------------------------------
  | Destroyed lifecycle hook
  |--------------------------------------------------
  */
  destroyed(){
    window.removeEventListener("beforeunload",this.removeSnackbar);
  }
};
</script>
